<template>
  <div @click="$emit('selectFloor', item)">
    <b-card
      :key="compKey"
      class="event pointer card small-card small-card-hover p-0 overflow-hidden text-center flex-item"
      :style="style"
      :class="item.key===floorSelected?'grid-item':''"
    >
      <div class="h-100">
        <div class="event--container w-100">
          <div class="h-100 d-flex justify-content-center align-items-center p-50 w-100">
            <p class="w-100 text-light html-text-ellipsis-2 mb-0 h5">
              {{ translate(item.name) }}
            </p>
          </div>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import { getImageResource } from '@/@core/utils/image-utils';
import Placeholder from '@/assets/images/placeholders/light/placeholder-dark.jpeg';
import { enumToInvertedObject, randomString } from '@core/utils/utils';
import { EventType } from '@copernicsw/community-common';
import { checkPermissions } from '@/@core/utils/roles-utils';
import { getCollectiveUrl } from '@/@core/utils/collective';
import { DateTime } from 'luxon';
import { translateTranslationTable } from '@/@core/libs/i18n/utils';

export default {
  name: 'RoomsItemCard',
  props: {
    item: {
      type: Object,
      required: true,
    },
    floorSelected: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      compKey: randomString(10),
      style: `background: url('${this.item.imageURL != null ? this.item.imageURL : Placeholder}'); background-size: cover`,
    };
  },
  computed: {
    imagePlaceholder() {
      return Placeholder;
    },
  },

  methods: {
    /*     selectFloor() {
      this.$emit('selectFloor', this.item);
    }, */
    setPlaceholder(e) {
      e.target.src = this.imagePlaceholder;
    },
    translate(field) {
      return translateTranslationTable(this.$store.state.locale.currentLocale, field);
    },
    getImageSrc(url) {
      return getImageResource(url);
    },
    currentLocale() {
      return this.$store.state.locale.currentLocale;
    },
    isStaff() {
      return this.$store.getters.currentCollective.isStaff;
    },
    timezone(timestamp) {
      return DateTime.fromISO(timestamp).offsetNameShort;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-include'; // Bootstrap includes

.grid-item {
  box-shadow: 0px 0px 15px -1px $primary !important;
}
.event {
  height: 14em;
  width: 14em;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 0.8em;
  border: 0;
  position: relative;

  &--container {
    height: 100%;
    background: linear-gradient(transparent, rgba(0, 0, 0, 0.9));
  }
 /*  &--text {
    height: 75%;
    position: absolute;
    bottom: 0;
  } */
}
.headline {
  font-weight: 200 !important;
}
.no-published {
  filter: grayscale(100%);
  opacity: 0.6;
  border-color: black;
  border-style: dashed !important;
  border-width: 3px !important;
}
</style>
