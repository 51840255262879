<template>
  <div class="rounded border p-1">
    <b-button
      class="float-right mr-2"
      variant="primary"
      v-if="isEditing && isStaff && floor.isPrincipalLayout"
      v-b-modal.create-floor-space-modal
      >Crear sector</b-button
    >
    <b-button
      class="float-right mr-2"
      variant="primary"
      v-if="isEditing && isStaff && floor.isPrincipalLayout"
      @click="editSpaceLayout"
      >Aplicar diseño de sectores</b-button
    >
    <div style="clear: both"></div>
    <!--<div class="d-flex rounded justify-content-center">
      <div
        v-for="space in floor.spaces"
        v-bind:key="space.key"
        class="border flex-item p-3"
      >
        <p class="text-center">{{ space.name }}</p>
        <div v-for="(row, index) of Object.values(prepareRows(space))" v-bind:key="index">

          <div v-for="seat in row" v-bind:key="seat.key" class="d-inline-block mr-1">
            <div
              @click="!isEditing ? selectSeat(seat, $event) : editSeat(seat, $event)"
              v-b-tooltip.hover.bottom="`${seat.name} - ${seat.price} €`"
            >
              <font-awesome-icon
                :icon="['fa', 'couch']"
                size="lg"
                :style="{ color: colorsByStatus[seat.status] }"
              />
            </div>
          </div>
        </div>
        <div class="text-center mt-2 mb-2">
        <b-button variant="primary"><feather-icon icon="PlusIcon"/></b-button>
        </div>
      </div>
    </div>-->
    <grid-layout
      :layout.sync="spacesWithLayout"
      :col-num="12"
      :row-height="30"
      :margin="[10, 10]"
      :vertical-compact="true"
      :use-css-transforms="true"
      :responsive="true"
      auto-size
      :is-draggable="isEditing && floor.isPrincipalLayout"
      :is-resizable="isEditing && floor.isPrincipalLayout"
      class="grid-layout"
      :class="isEditing ? 'mt-3' : ''"
    >
      <grid-item
        v-for="space of spacesWithLayout"
        :key="space.key"
        :i="space.key"
        :x="space.x"
        :y="space.y"
        :w="space.w"
        :h="space.h"
        class="grid-item space"
        :style="isEditing ? 'border: 1px solid grey' : ''"
      >
        <div class="position-absolute" style="right: 10px; top: 10px">
          <b-button
            variant="primary"
            v-if="isEditing && isStaff && floor.isPrincipalLayout"
            @click="addSeats(space)"
          >
            <feather-icon icon="PlusIcon"
          /></b-button>
          <b-button
            variant="primary"
            class="ml-2"
            v-if="isEditing && isStaff && floor.isPrincipalLayout"
            @click="AskIfDeleteSpace(space)"
          >
          <feather-icon icon="TrashIcon"/>
          </b-button>
          <b-button
            variant="primary"
            v-if="isEditing && isStaff && !floor.isPrincipalLayout"
            @click="editSeatsPriceAndAvailability(space, null)"
          >
          <feather-icon icon="Edit2Icon"/>
          </b-button>
        </div>

        <p class="text-center mt-1">{{ space.name }}</p>

        <grid-layout
          :layout.sync="seatsWithLayout[space.key]"
          :col-num="12"
          :row-height="30"
          :margin="[10, 10]"
          :vertical-compact="true"
          :use-css-transforms="true"
          :responsive="true"
          auto-size
          :is-draggable="isEditing && floor.isPrincipalLayout"
          :is-resizable="isEditing && floor.isPrincipalLayout"
          class="grid-layout"
          :class="isEditing ? 'mt-3' : ''"
        >
          <grid-item
            v-for="seats of seatsWithLayout[space.key]"
            :key="seats.i"
            :i="seats.i"
            :x="seats.x"
            :y="seats.y"
            :w="seats.w"
            :h="seats.h"
            class="grid-item"
            :style="isEditing ? 'border: 1px solid grey' : ''"
          >
            <div class="position-absolute" style="right: 20px; top: 15px">
              <b-button
                variant="primary"
                v-if="isEditing && isStaff && floor.isPrincipalLayout"
                @click="addSeats(space, seats.i)"
              >
                <feather-icon icon="PlusIcon"
              /></b-button>
              <b-button
                class="ml-2"
                variant="primary"
                v-if="isEditing && isStaff && floor.isPrincipalLayout"
                @click="askIfDeleteRow(space, seats.i)"
              >
                <feather-icon icon="TrashIcon"
              /></b-button>
              <b-button
            variant="primary"
            v-if="isEditing && isStaff && !floor.isPrincipalLayout"
            @click="editSeatsPriceAndAvailability(space, seats.i)"
          >
            <feather-icon icon="Edit2Icon"
          /></b-button>
            </div>
            <div class="text-center pt-2">
              {{ seats.i }}

              <div
                v-for="seat in seats.seats"
                v-bind:key="seat.key"
                class="d-inline-block mr-1"
              >
                <div
                  @click="
                    !isEditing
                      ? !floor.isPrincipalLayout
                        ? selectSeat(seat, $event)
                        : () => {}
                      : editSeat(space, seat)
                  "
                  v-b-tooltip.hover.bottom="
                    `${seat.name} ${
                      !floor.isPrincipalLayout ? `- ${seat.price} €` : ''
                    }`
                  "
                >
                  <font-awesome-icon
                    :icon="['fa', 'couch']"
                    size="lg"
                    :style="{ color: colorsByStatus[seat.status] }"
                  />
                </div>
              </div>
            </div>
          </grid-item>
        </grid-layout>

        <!-- <div
          v-for="(row, index) of Object.values(prepareRows(space))"
          v-bind:key="index"
          class="text-center"
        >
          <div
            v-for="seat in row"
            v-bind:key="seat.key"
            class="d-inline-block mr-1"
          >
            <div
              @click="
                !isEditing ? selectSeat(seat, $event) : editSeat(seat, $event)
              "
              v-b-tooltip.hover.bottom="`${seat.name} - ${seat.price} €`"
            >
              <font-awesome-icon
                :icon="['fa', 'couch']"
                size="lg"
                :style="{ color: colorsByStatus[seat.status] }"
              />
            </div>
          </div>
        </div> -->
      </grid-item>
    </grid-layout>
    <create-floor-space @createdSpace="createdSpaceMethod" />
    <create-seats-modal @createdSpace="createdSpaceMethod" />
    <edit-seat-modal v-if="selectedSeat" @createdSpace="createdSpaceMethod" />
    <edit-seats-row-modal @createdSpace="createdSpaceMethod" />
    <unshare-modal  v-model="unshareSpace" modelType="spaces" @remove-item="deleteSpace" />
    <unshare-modal  v-model="unshareRow" modelType="seatRow" @remove-item="deleteRow" />
  </div>
</template>

<script>
import CreateFloorSpace from "./modals/createFloorSpace.vue";
import VueGridLayout from "vue-grid-layout";
import CreateSeatsModal from "./modals/createSeats.vue";
import EditSeatModal from "./modals/editSeat.vue";
import EditSeatsRowModal from "./modals/editSeatsRow.vue";
import UnshareModal from '@/@core/components/modal/UnshareModal.vue';

export default {
  name: "FloorMap",
  components: {
    CreateFloorSpace,
    CreateSeatsModal,
    EditSeatModal,
    UnshareModal,
    EditSeatsRowModal,
    GridLayout: VueGridLayout.GridLayout,
    GridItem: VueGridLayout.GridItem,
  },
  data() {
    return {
      isStaff: this.$store.getters.currentCollective.isStaff,
      spaceLayout: this.floor?.spacesLayout || [],
      spacesWithLayout: [],
      unshareSpace: false,
      unshareRow: false,
      seatsWithLayout: {},
    };
  },
  mounted() {
    setTimeout(async () => {
      try {
        const layout = await this.loadSpaceLayout();
        this.spacesWithLayout = JSON.parse(JSON.stringify(layout.spaces));
        this.seatsWithLayout = JSON.parse(JSON.stringify(layout.seats));
      } catch {}
    }, 500);
  },
  computed: {
    selectedSeat() {
      return this.$store.getters.selectedSeat;
    },
    floor() {
      return this.$store.getters.selectedFloor;
    },
    cart() {
      return this.$store.getters.cart;
    },
    colorsByStatus() {
      return {
        0: "#414141", // Normal
        1: "#B3B3B3", // No disponible
        2: "#C54A4A", // Ocupado
        3: "#DDBF29", // Reservado
        4: "#2DCBD8", // Seleccionado
      };
    },
    isEditing() {
      return this.$store.getters.presencialRoomEditingMode;
    },
  },
  watch: {
    async floor() {
      const layout = await this.loadSpaceLayout();
      this.spacesWithLayout = JSON.parse(JSON.stringify(layout.spaces));
      this.seatsWithLayout = JSON.parse(JSON.stringify(layout.seats));
    },
  },
  methods: {
    editSpaceLayout() {
      this.$store.dispatch("editItem", {
        noSet: true,
        item: {
          itemType: "spaces/layout",
          requestConfig: {
            floorKey: this.floor.key,
            layout: this.spacesWithLayout.map((x) => ({
              x: x.x,
              y: x.y,
              h: x.h,
              w: x.w,
              i: x.i,
              key: x.key,
            })),
          },
        },
      });
      for (let row of this.spacesWithLayout) {
        this.$store.dispatch("editItem", {
          noSet: true,
          item: {
            itemType: "seats/layout",
            requestConfig: {
              spaceKey: row.key,
              layout: this.seatsWithLayout[row.key].map((x) => ({
                x: x.x,
                y: x.y,
                h: x.h,
                w: x.w,
                i: x.i,
              })),
            },
          },
        });
      }
    },
    async editSeatsPriceAndAvailability(space, row) {
      await this.$store.commit("SET_ITEM_LITERAL", {
        itemType: "selectedSpace",
        data: space,
      });
      await this.$store.commit("SET_ITEM_LITERAL", {
        itemType: "selectedRow",
        data: row
      });

      this.$bvModal.show("edit-seats-row-modal");
    },
    editSeat(seat, $event) {
      console.log(seat);
    },
    async addSeats(space, row = null) {
      await this.$store.commit("SET_ITEM_LITERAL", {
        itemType: "selectedSpace",
        data: space,
      });

      await this.$store.commit("SET_ITEM_LITERAL", {
        itemType: "selectedRow",
        data:
          row != null
            ? row
            : space.rowSchema != null
            ? Object.keys(space.rowSchema).length + 1
            : 1,
      });

      this.$bvModal.show("create-seats-modal");
    },

    async editSeat(space, seat) {
      await this.$store.commit("SET_ITEM_LITERAL", {
        itemType: "selectedSpace",
        data: space,
      });

      await this.$store.commit("SET_ITEM_LITERAL", {
        itemType: "selectedSeat",
        data: seat,
      });

      this.$bvModal.show("edit-seats-modal");
    },
    loadSpaceLayout() {
      const spaces = [];
      const seatLayouts = {};
      try {
        this.spaceLayout = this.floor.spacesLayout || [];
        for (let row of this.floor.spaces) {
          if(row.deletedAt != null) {
            continue;
          }
          let layoutOfItem = this.spaceLayout.find((x) => x.i === row.key);
          let layout = {
            i: layoutOfItem?.i || row.key,
            x: layoutOfItem?.x || 0,
            y: layoutOfItem?.y || 0,
            h: layoutOfItem?.h || 2,
            w: layoutOfItem?.w || 12,
          };

          if (!layoutOfItem) {
            this.spaceLayout.push(layout);
          }

          spaces.push({ ...row, ...layout });

          seatLayouts[row.key] = this.loadSeatsLayout(row);
        }

        return { spaces: spaces, seats: seatLayouts };
      } catch (e) {
        console.log(e);
        return [];
      }
    },
    loadSeatsLayout(space) {
      const seatRows = this.prepareRows(space);
      try {
        const seatsLayout = [];

        for (let row of Object.keys(seatRows)) {
          let layoutOfItem = space.seatsLayout?.find((x) => x.i === row);

          let layout = {
            i: layoutOfItem?.i || row,
            x: layoutOfItem?.x || 0,
            y: layoutOfItem?.y || 0,
            h: layoutOfItem?.h || 2,
            w: layoutOfItem?.w || 12,
          };

          seatsLayout.push({ seats: seatRows[row], ...layout });
        }

        return seatsLayout;
      } catch (e) {
        console.log(e);
        return [];
      }
    },
    createdSpaceMethod() {
      this.$emit("createdSpace", true);
    },
    prepareRows(space) {
      const seats = {};

      let index = 0;

      for (let row of Object.keys(space.rowSchema || {})) {
        seats[row] = [];

        for (let seat of space.seats) {
          if (space.rowSchema[row].includes(seat.key)) {
            seats[row].push(seat);
          }
        }
        index++;
      }

      return seats;
    },
    async selectSeat(seat, e) {
      const status = Number(seat.status) || 0;

      if (status === 0) {
        seat.status = 4;
        await this.$store.commit("SET_ITEM_LITERAL", {
          itemType: "cart",
          data: [...this.$store.getters.cart, seat],
        });
        return;
      }

      if (status === 4) {
        seat.status = 0;
        const cart = [...this.$store.getters.cart];
        const index = cart.findIndex((x) => {
          x.key === seat.key;
        });
        cart.splice(index, 1);
        await this.$store.commit("SET_ITEM_LITERAL", {
          itemType: "cart",
          data: cart,
        });
        return;
      }
    },
    async AskIfDeleteSpace(space) {
      await this.$store.commit("SET_ITEM_LITERAL", {
        itemType: "selectedSpace",
        data: space,
      });

      this.unshareSpace = true;
    },
    async askIfDeleteRow(space, row) {
      await this.$store.commit("SET_ITEM_LITERAL", {
        itemType: "selectedSpace",
        data: space,
      });

      await this.$store.commit("SET_ITEM_LITERAL", {
        itemType: "selectedRow",
        data: row,
      });

      this.unshareRow = true;
    },
    async deleteSpace() {
      await this.$store.dispatch("createItem", {
        noSet: true,
        item: {
          itemType: "delete/space",
          requestConfig: {
            floorKey: this.floor.key,
            spaceKey: this.$store.getters.selectedSpace.key
          }
        }
      });
      this.createdSpaceMethod();
    },
    async deleteRow() {
      await this.$store.dispatch("createItem", {
        noSet: true,
        item: {
          itemType: "delete/row",
          requestConfig: {
            floorKey: this.floor.key,
            spaceKey: this.$store.getters.selectedSpace.key,
            row: this.$store.getters.selectedRow
          }
        }
      });
      this.createdSpaceMethod();
    }
  },
};
</script>
<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-include"; // Bootstrap includes

@include media-breakpoint-up(lg) {
  .grid-layout {
    margin: -10px;
  }
}

.space {
  border: 1px solid black;
}
.grid-layout--landing {
  display: flex;
  flex-wrap: wrap;
  .grid-item {
    flex-basis: 100%;
    &--chat,
    &--liveStreaming {
      flex-basis: 50%;
    }
  }
}
.grid-layout--disabled {
  .grid-item {
    margin-bottom: $spacer;
    &--posts > div,
    &--communication > div {
      max-height: 100vh;
    }
  }
}

.grid-layout--editing {
  > .grid-item {
    box-shadow: 0px 0px 10px -2px $primary;
    border-radius: $card-border-radius;
    &.resizing {
      border-radius: none;
      border: 3px solid $primary;
    }
    &::v-deep > .vue-resizable-handle {
      bottom: 8px;
      right: 8px;
      background-position: center;
      background-size: 47%;
    }
  }
}
</style>
