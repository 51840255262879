<template>
  <b-modal id="edit-seats-modal" title="Editar asiento" centered hide-footer>
    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <b-form @submit.prevent="handleSubmit(handleCreateItem)">
        <validation-provider
          #default="validationContext"
          name="Nombre"
          rules="required"
        >
          <b-form-group label="Nombre" label-for="text">
            <b-form-input
              id="text"
              v-model="item.name"
              autofocus
              :state="getValidationState(validationContext)"
              trim
              placeholder="Escribe el nombre aquí"
              :disabled="isSaving"
            />
          </b-form-group>
        </validation-provider>
        <validation-provider
          #default="validationContext"
          name="Precio"
          rules="required"
          v-if="!selectedFloor.isPrincipalLayout"
        >
          <b-form-group label="Precio" label-for="price">
            <b-form-input
              id="price"
              type="number"
              v-model="item.price"
              :state="getValidationState(validationContext)"
              trim
              placeholder="Escribe el precio aquí"
              :disabled="isSaving"
            />
          </b-form-group>
        </validation-provider>

        <validation-provider
          #default="validationContext"
          name="Descuento"
          v-if="!selectedFloor.isPrincipalLayout"
        >
          <b-form-group label="Descuento" label-for="discount">
            <b-form-input
              id="discount"
              type="number"
              v-model="item.discount"
              :state="getValidationState(validationContext)"
              trim
              placeholder="Escribe el descuento aquí en formato euros"
              :disabled="isSaving"
            />
          </b-form-group>
        </validation-provider>

        <validation-provider
          #default="validationContext"
          name="Estado"
          rules="required"
          v-if="!selectedFloor.isPrincipalLayout"
        >
          <b-form-group label="Estado del asiento" label-for="status">
            <v-select v-model="item.status" :options="statusOptions" :reduce="(status) => status.id" :state="getValidationState(validationContext)" :disabled="isSaving"/>
          </b-form-group>
        </validation-provider>

        <validation-provider
          #default="validationContext"
          name="Tipo"
        >
          <b-form-group label="Tipo" label-for="type">
            <b-form-input
              id="type"
              v-model="item.type"
              :state="getValidationState(validationContext)"
              trim
              placeholder="Escribe el tipo de asiento aquí"
              :disabled="isSaving"
            />
          </b-form-group>
        </validation-provider>
        <!-- Submit button -->
        <div class="float-right mb-50 mt-1">
          <!-- Missing to implement BE -->
          <b-button
            variant="primary"
            class="float-right"
            :disabled="isSaving"
            type="submit"
          >
            <span v-if="isSaving" class="d-flex"
              ><b-spinner
                class="mr-1"
                small
                variant="white"
                label="Cargando..."
              />
              Editar
            </span>
            <span v-else>Editar</span>
          </b-button>
        </div>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import formValidation from "@core/comp-functions/forms/form-validation";
import ToastNotificationsMixin from "@core/mixins/toast-notifications/ToastNotificationsMixin";
import vSelect from 'vue-select';
export default {
  name: "EditSeatModal",
  components: {
    formValidation,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [ToastNotificationsMixin],
  data() {
    return {
      item: {},
      isSaving: false,
      route: this.$route.params,
    };
  },
  setup() {
    const { getValidationState } = formValidation(() => {});

    return {
      getValidationState,
    };
  },
  computed: {
    statusOptions() {
      return [
        {
          label: "Disponible",
          id: 0,
        },
        {
          label: "No disponible",
          id: 1,
        },
        {
          label: "Ocupado",
          id: 2,
        },
        {
          label: "Reservado",
          id: 3,
        }
      ]
    },
    selectedFloor() {
      return this.$store.getters.selectedFloor;
    },
    selectedSpace() {
      return this.$store.getters.selectedSpace;
    },
    selectedSeat() {
      return this.$store.getters.selectedSeat
    },
    selectedRow() {
      return this.$store.getters.selectedRow;
    },
  },
  mounted(){
    this.item = {
      name: this.selectedSeat.name,
      price: this.selectedSeat.price,
      type: this.selectedSeat.type,
      status: this.selectedSeat.status
    }
  },
  methods: {
    async handleCreateItem() {
      try {
        if (!this.selectedSpace) {
          return;
        }

        this.isSaving = true;

        await this.$store.dispatch("editItem", {
          item: {
            itemType: "seats",
            requestConfig: {
              floorKey: this.selectedFloor.key,
              spaceKey: this.selectedSpace.key,
              seatKey: this.selectedSeat.key,
              ...this.item
            },
          },
          noSet: true,
        });

        this.$emit("createdSpace", true);

        this.notifySuccess(this.$t("success-message.general-success-create"));

        this.$bvModal.hide("edit-seats-modal");

        this.item = {};
        this.isSaving = false;
      } catch (e) {
        console.log(e);
        this.notifyError(this.$t("error-message.general-error"));
        this.isSaving = false;
      }
    },
  },
};
</script>
