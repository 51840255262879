<template>
  <div>
    <b-button
      v-if="!selectedEvent"
      class="float-right ml-2"
      variant="primary"
      @click="$emit('returnToEvent', true)"
    >
      Volver
    </b-button>
    <b-button

      v-if="!isEditing && isStaff"
      class="float-right"
      variant="primary"
      @click="changeMode"
    >
      Modo edición
    </b-button>
    <b-button
      v-if="isEditing && isStaff"
      class="float-right"
      variant="primary"
      @click="changeMode"
    >
      Salir del modo edición
    </b-button>
    <b-button
      v-if="isEditing && isStaff && !selectedEvent"
      v-b-modal.create-floor-modal
      class="float-right mr-2"
      variant="primary"
    >
      Crear piso
    </b-button>
    <h2>{{ room.name }}</h2>
    <h3 v-if="selectedEvent">
      {{
        typeof selectedEvent === "object"
          ? translate(selectedEvent.name)
          : selectedEvent.name
      }}
    </h3>
    <p>Selecciona una planta:</p>
    <div class="d-flex justify-content-center flex-wrap mt-2 w-100">
      <rooms-item-card
        v-for="floor in floors"
        :key="floor.key"
        :item="floor"
        :floor-selected="floorSelected"
        class="ml-1 mr-1"
        @selectFloor="selectFloor(floor)"
      />
    </div>
    <div v-if="floor != null" :key="keyOfMap" class="mt-3">
      <floor-map
        :floor="floor"
        @createdSpace="fetchDataAndReloadSpace"
        @createSeats="fetchDataAndReloadSpace"
      />
    </div>
    <b-card
      v-if="cart != null && cart.length > 0"
      :class="isMobile ? 'mt-5 border-top p-2' : 'mt-5 position-fixed p-2'"
      :style="isMobile ? 'color: #000' : 'z-index: 999; top: 250px; right: 150px; color: #000'"
    >
      <h3 class="mt-2">
        Entradas seleccionadas
      </h3>
      <ol class="mt-3" style="text-decoration: none; list-style: none;" :style="isMobile ? '' : 'max-height: 150px; overflow-y: scroll'">
        <li v-for="(item, index) of cart" :key="index">
          <p class="mt-1 font-weight-bold">
            Entrada {{ index + 1 }}:
          </p>
          {{ item.name }} - {{ item.price }} € - Asiento numero
          {{ item.seatNumber }}
        </li>
      </ol>
      <p class="mt-2 font-weight-bold">
        Precio total: {{ this.totalPrice }} €
      </p>
      <b-button
        variant="primary"
        class="mt-2"
        @click="handlePay"
      >
      Comprar
      </b-button>
      <b-button
        variant="primary"
        class="mt-2 ml-2"
      >
      Pagar con abono
      </b-button>
    </b-card>

    <form
      id="redsysForm"
      :action="redSysURL"
      method="POST"
      style="visibility: hidden"
      target="_blank"
    >
      <input
        type="text"
        name="Ds_MerchantParameters"
        hidden
      >
      <input
        type="text"
        name="Ds_Signature"
        hidden
      >
      <input
        type="text"
        name="Ds_SignatureVersion"
        hidden
      >
      <input id="submitRedsys" type="submit">
    </form>
    <create-floor-modal @createdFloor="fetchData" />
  </div>
</template>
<script>
import { randomString } from '@/@core/utils/utils';
import { translateTranslationTable } from '@/@core/libs/i18n/utils';
import floorMap from './floorMap.vue';
import RoomsItemCard from './RoomsItemCard.vue';
import CreateFloorModal from './modals/createFloor.vue';

export default {
  name: 'FloorsView',
  components: {
    RoomsItemCard,
    floorMap,
    CreateFloorModal,
  },
  props: {
    room: {
      type: Object,
      required: true,
    },
    selectedEvent: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      isLoading: true,
      floorSelected: '',
      isStaff: this.$store.getters.currentCollective.isStaff,
      keyOfMap: randomString(10),
    };
  },
  computed: {
    redSysURL() {
      if (process.env.VUE_APP_ENV !== 'production') {
        return process.env.VUE_APP_PAY_REDSYS_TEST;
      }
      return process.env.VUE_APP_PAY_REDSYS;
    },
    floor() {
      return this.$store.getters.selectedFloor;
    },
    floors() {
      return this.$store.getters.floors.unpaginated;
    },
    cart() {
      return this.$store.getters.cart;
    },
    totalPrice() {
      if (this.cart.length === 1) {
        return this.cart[0].price;
      }
      return this.cart.reduce((x, y) => x + Number(y.price), 0);
    },
    isMobile() {
      return window.innerWidth < 700;
    },
    isEditing() {
      return this.$store.getters.presencialRoomEditingMode;
    },
  },
  async created() {
    await this.fetchData();
    await this.$store.commit('SET_ITEM_LITERAL', {
      itemType: 'selectedFloor',
      data: null,
    });
    await this.$store.commit('SET_ITEM_LITERAL', {
      itemType: 'cart',
      data: [],
    });
    this.isLoading = false;
  },
  methods: {
    async handlePay() {
      const seats = [];

      this.cart.map((x) => {
        seats.push(x.key);
      });

      const response = await this.$store.dispatch('processPayment', {
        itemType: 'v1/payRedsysRooms',
        data: {
          roomKey: this.room.key,
          seatKeys: seats,
          communitySlug: this.selectedEvent.slug,
        },
      });

      for (const row of Object.keys(response)) {
        document.getElementsByName(row)[0].value = response[row];
      }
      // document.getElementById("submitRedsys").click();
      document.getElementById('redsysForm').submit();
    },
    translate(field) {
      return translateTranslationTable(
        this.$store.state.locale.currentLocale,
        field,
      );
    },
    async fetchDataAndReloadSpace() {
      await this.fetchData();
      this.selectFloor(this.floors.find((x) => x.key === this.floor?.key));
      this.keyOfMap = randomString(10);
    },
    async selectFloor(floor) {
      this.floorSelected = floor.key;
      await this.$store.commit('SET_ITEM_LITERAL', {
        itemType: 'selectedFloor',
        data: floor,
      });
    },
    async fetchData() {
      const response = await this.$store.dispatch('getItems', {
        itemType: 'floors',
        forceAPICall: true,
        requestConfig: {
          roomKey: this.room.key,
          eventSlug: this.selectedEvent?.slug,
        },
      });
      return response;
    },
    async changeMode() {
      await this.$store.commit('SET_ITEM_LITERAL', {
        itemType: 'presencialRoomEditingMode',
        data: !this.$store.getters.presencialRoomEditingMode,
      });
    },
  },
};
</script>
