<template>
  <b-modal
    id="create-floor-space-modal"
    title="Crear sector"
    centered
    hide-footer
  >
    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <b-form @submit.prevent="handleSubmit(handleCreateItem)">
        <validation-provider
          #default="validationContext"
          name="Nombre"
          rules="required"
        >
          <b-form-group label="Nombre" label-for="text">
            <b-form-input
              id="text"
              v-model="item.name"
              autofocus
              :state="getValidationState(validationContext)"
              trim
              placeholder="Escribe el nombre aquí"
              :disabled="isSaving"
            />
          </b-form-group>
        </validation-provider>
        <validation-provider
          #default="validationContext"
          name="Capacidad"
          rules="required"
        >
          <b-form-group label="Capacidad" label-for="capacity">
            <b-form-input
              id="capacity"
              type="number"
              v-model="item.capacity"
              :state="getValidationState(validationContext)"
              trim
              placeholder="Escribe la capacidad aquí"
              :disabled="isSaving"
            />
          </b-form-group>
        </validation-provider>
        <!-- Submit button -->
        <div class="float-right mb-50 mt-1">
          <!-- Missing to implement BE -->
          <b-button
            variant="primary"
            class="float-right"
            :disabled="isSaving"
            type="submit"
          >
            <span v-if="isSaving" class="d-flex"
              ><b-spinner
                class="mr-1"
                small
                variant="white"
                label="Cargando..."
              />
              Crear
            </span>
            <span v-else>Crear</span>
          </b-button>
        </div>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import formValidation from "@core/comp-functions/forms/form-validation";
import ToastNotificationsMixin from "@core/mixins/toast-notifications/ToastNotificationsMixin";
export default {
  name: "CreateFloorSpace",
  components: {
    formValidation,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [ToastNotificationsMixin],
  data() {
    return {
      item: {},
      isSaving: false,
      route: this.$route.params
    };
  },
  setup() {
    const { getValidationState } = formValidation(() => {});

    return {
      getValidationState,
    };
  },
  computed: {
    selectedFloor() {
      return this.$store.getters.selectedFloor
    }
  },
  methods: {
    async handleCreateItem() {

        try {

          if(!this.selectedFloor) {
            return;
          }

        this.isSaving = true;

        await this.$store.dispatch("createItem", {
            item: {
                itemType: "spaces",
                requestConfig: {
                    floorKey: this.selectedFloor.key,
                    ...this.item
                }
            },
            noSet: true
        });

        this.$emit("createdSpace", true);

        this.notifySuccess(this.$t("success-message.general-success-create"));

        this.$bvModal.hide("create-floor-space-modal");

        this.item = {};
        this.isSaving = false;

        }catch(e) {
            console.log(e);
            this.notifyError(this.$t("error-message.general-error"));
            this.isSaving = false;
        }
    },
  },
};
</script>
